@font-face {
    font-family: 'Grotesk';
    src: url("../fonts/SpaceGrotesk.ttf");
}
@font-face{
    font-family: 'Inter';
    src: url("../fonts/Inter.ttf");
}
body{
    font-family: 'Grotesk' !important;
}

[data-bs-theme=dark] {
    --bs-tertiary-bg-rgb: 0, 0, 0;
}

.btn-dark {
    --bs-btn-bg: #000;
    --bs-btn-border-color: #000;
}

/*
*  Navbar
*/
.float-right {
    float: right;
}
.float-left{
    float: left;
}

.text-right {
    text-align: right;
}

.positin-relative {
    position: relative;
}


.navbar-drawer {
    position: relative;

    .buttom-accordion {
        position: absolute;
        bottom: 16px;
        margin-right: 16px;

        .Mui-expanded::before,
        .MuiAccordion-root::before {
            position: absolute;
            left: 0;
            top: -1px;
            right: 0;
            height: 1px;
            content: "";
            opacity: 1;
            background-color: rgba(0, 0, 0, 0);
        }

        .MuiAccordion-root {
            color: #807E7E;
        }
    }

    .Mui-expanded::before {
        position: absolute;
        left: 0;
        top: -1px;
        right: 0;
        height: 1px;
        content: "";
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.06);
    }

    .MuiAccordion-root {
        box-shadow: none !important;
    }

    .Mui-disabled {
        opacity: 1 !important;
        background-color: transparent !important;

        .MuiAccordionSummary-expandIconWrapper {
            display: none !important;
            border: none !important;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .Mui-expanded {
        margin: 0 !important;
    }

    .MuiAccordionDetails-root {
        padding: 0px 16px 16px 16px !important;
    }

    .MuiAccordionSummary-content::before,
    .MuiAccordionSummary-expandIconWrapper::before {
        background-color: rgba(0, 0, 0, 0);
    }
}
.company-information-logo{
    display: inline-block;
}
@media screen and (max-width: 576px) {
    .company-information-logo{
        float: right;
        margin: 0px !important;
        button{
            margin-top: 4px !important;
            display: block  ;
            margin: 0px;
        }
    }
}
.product-accrodin .MuiAccordionSummary-root {
    padding: 0 !important;
    border-bottom: 1px solid #a3a3a3;
}

.product-accrodin .MuiAccordionDetails-root {
    padding: 0px;
    padding-top: 16px ;
}

.product-accrodin .MuiAccordionSummary-content {
    font-size: var(--font-18);
    font-weight: 500;
    line-height: 32px;
    text-align: left;

}

.product-accrodin .MuiPaper-root {
    box-shadow: none !important;
}

.header-nav {
    padding: 12px;
    background-color: black;
    color: white;
    text-align: center;
}

.header-title {
    // width: 90%;
    margin: auto;
    text-align: center;
}

.header-title h1 {
    margin-bottom: 12px;
    font-size: 54px;
    font-weight: 500;
    line-height: 58px;
    letter-spacing: -1px    ;
}
.header-title p{
    font-size: 18px;
    font-family: 'Inter';
}

.breadcrumb-nav {
    padding: 12px;
    text-align: center;
}

footer.footer,
.navbar-brand {
    padding-top: 0;

    .logo {
        width: 170px;
        margin-left: 12px;
        margin-right: 12px;
    }

    img:not(.logo) {
        width: 20px;
    }
}


footer.footer {
    padding: 36px 24px;
    min-height: 100px;
    background-color: #FAFAFB;

    div {
        text-align: center;
        padding-top: 12px;
        padding-bottom: 12px;
    }

    div.center-column-footer {
        img {
            max-width: 40px;
            margin-left: 8px;
            margin-right: 8px;
        }
    }
}

.navbar-nav {
    .nav-drop-down-hover:hover {
        background-color: rgba(#000, 0.2);
        cursor: pointer;
    }
}


.navbar-nav {
    .nav-item {
        .nav-link {
            color: #fff !important;
        }
    }

    button.btn-dark {
        --bs-btn-bg: #000;
        --bs-btn-border-color: #000;
    }

    button.btn {
        --bs-border-radius: 50%;
        --bs-btn-padding-x: 0.375rem;
        --bs-btn-padding-y: 0.375rem;
    }

    button.bell {
        width: 40px;
    }

    button.language-switcher {
        width: 80px;
        --bs-border-radius: 25px;

        text-transform: uppercase;

        img {
            margin-right: 8px;
            margin-left: 8px;
        }
    }

    button.avatar {
        --bs-border-radius: 25px;

        span {
            float: left;
            display: block;
            width: 26px;
            height: 26px;
            line-height: 26px;
            text-align: center;
            background-color: #fff;
            color: #000;
            border-radius: 25px;
            font-size: 11px;
            font-weight: bold;
            margin-right: 8px;
        }

        &:after {
            margin-left: 0.555em;
        }

        &.d-lg-none {
            width: 40px;
            height: 40px;
        }
    }
}


@media (min-width: 992px) {

    nav.navbar {
        padding-left: 24px;
        padding-right: 24px;

        .navbar-brand {
            margin-right: 0;
        }


    }

    .navbar-nav {
        .nav-item {
            margin: 0 8px;
        }
    }
}

.mobile-view {
    margin-right: 8px;

    .navbar-nav {
        flex-direction: row;
        position: absolute;
        top: 12px;
        right: 16px;
        text-align: right;

        .dropdown-menu {
            position: absolute;
            top: 40px;
        }

        .dropdown-menu[data-bs-popper] {
            left: unset;
        }

        .dropdown-toggle::after {
            content: none;
        }
    }

    &:after {
        content: '';
        display: flex;
        clear: both;
    }
}

.product-section {
    //   display: grid;
    //grid-template-columns: 50% 50%;
    //  margin-bottom: 24px;
    overflow-y: hidden;
}

.product-tab .Mui-selected {
    color: black !important;
    font-weight: 700;
}

.product-tab {
    input {
        color: #000 !important;
    }
}

.product-tab .MuiTabs-indicator {
    background-color: black;
}

.product-tab .MuiTabs-root {
    min-height: 36px;
    height: 36px;
}

.product-tab .MuiTab-root {
    font-family: 'Grotesk' !important;
    text-transform: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    padding: 8px 24px !important;
    margin: 0px !important;
    min-height: 36px !important;
    max-height: 36px !important;
    height: 36px !important;


}

.product-container {
    font-family: 'Inter';
    //    width: 100%;
    box-sizing: border-box;
    //   padding: 24px 150px;
}


.sort-button {
    border: none;
    font-weight: 500;
    background-color: transparent;
    color: black;
}

.sort-button:focus {
    border: none;
    outline: none;
    vertical-align: text-bottom;
}

.sort-button:hover {
    cursor: pointer;
}

.add-product {
    font-family: 'Inter';
    .sticky-save-cancel {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
    }

    .sticky-header {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background-color: white !important;
        z-index: 999 !important;

        .row {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

@media screen and (min-width: 576px) {

    .header-title {
        width: 80%;
    }

    .product-section {
        grid-template-columns: 100%;

    }

    .product-tab2 {
        // margin-top: 16px;
    }

    .product-container {
        padding: 12px 30px;
    }

}

.form-check-input {
    border: 2px solid black;
    padding: 3px;
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e") !important
}

.form-check-input:checked {
    background-color: black;
    border-color: black;
    outline: none;
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e") !important
}

.form-switch .form-check-input:focus {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e");
}

.form-switch .form-check-input:checked:focus {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}


.form-check-input:focus {
    border: 2px solid black;
    padding: 3px;

    box-shadow: none;
    outline: none;
}

@media screen and (min-width:768px) {
    .header-title {
        width: 70%;
    }

    .product-container {
        //  padding: 16px 50px;
    }
}

@media screen and (min-width:992px) {
    .header-title {
        width: 50%;
    }

    .product-container {
        padding: 18px 52px;
    }
}

@media screen and (min-width:1200px) {
    .header-title {
        width: 50%;
    }

    .product-container {
        padding: 52px 166px;
    }
}



@media screen and (min-width:1400px) {
    .header-title {
        width: 40%;
    }

    .product-container {
        // padding: 24px 140px;
    }
}





.carousel * {
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.black-button {
    width: 100%;
    background-color: black;
    padding: 8px 20px;
    margin: auto;
    color: white;
    border-radius: 8px;
    display: inline-block;

}

button.black-button:hover,
button.white-button:hover {
    cursor: pointer;
}

.white-button {
    width: 100%;
    background-color: white;
    padding: 8px 20px;
    margin: auto;
    color: black;
    border-radius: 8px;
    display: inline-block;
}

.order-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;

}

.suggest-price-modal-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    .input-group-text{
        border-left-color: transparent;
        background-color: transparent;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    h3{
        font-size: 20px;
        font-weight: 500;
        font-family: 'Grotesk';
    }
    h4{
        font-size: 16px;
        font-weight: 700;
        color: #121212;
    }
    .add-table p {
        font-size: 16px;
        font-weight: 600;
    }
    input {
        border-right-color: transparent;
    }
    input:focus{
        box-shadow: none;
        outline: none;
        border-color: #dee2e6;
        border-right-color: transparent;
    }
}

.no-border{
    border: none !important
}
.border-bottom{
    border-bottom: 1px solid #EAEAEA !important;

}

.MuiStack-root{
    border: 1px solid #EAEAEA !important;
    border-radius: 6px;
    padding: 0.375rem 0.75rem;
    overflow: hidden !important;
    height: min-content !important;
    .MuiOutlinedInput-notchedOutline{
        border: none;
    }
    input{
        padding: 0;
    }
    button{
        position: absolute;
        right: 0px;
    }
}
.order-pagination {
    .MuiPaginationItem-root.Mui-selected {
        background-color: #F5F5F5 !important;
        color :black;
    }
    .MuiPaginationItem-root{
        font-size: 18px !important;
        font-weight: 600 !important;
    }
}
.green-text{
    color: #157147;
    font-size: 14px;
    font-family: 'Grotesk';
    svg{
        width: 20px;
        height: 20px;
        margin-right: 4px;
        position: relative;
        top: -2px;
    }
}
.add-table {
    width: 100%;
    .disable-input{
        border-color: #A7A7A7;
        background-color: #F5F5F5;
        color: #A7A7A7
      
    }
    .profile-logo-td >* {
margin-right: 32px;
    }
    .error-field {
      padding-right: 1px;
      border-radius: 6px;
      border: 1px solid red !important;
    }
    .error-p{
      margin: 0px !important;
      padding: 0px !important;
      font-size: 12px !important;
      font-weight: 400 !important;
      line-height: 20px !important;
      color: red  !important;
    }
    .MuiAutocomplete-root {
  
  
      border: none;
      color: #CBCBCB !important;
  
      input {
        border-radius: 4px;
  
        border: 1px solid #EAEAEA;
        box-shadow: none;
        color: black !important;
      }
  
      input:focus {
        color: black;
      }
  
      .form-control:focus {
        border-color: #EAEAEA;
      }
    }
  
    .MuiAutocomplete-root div:focus-visible {
      border: none !important;
      outline: none !important;
    }
    
    tr {
      display: block;
      padding-top: 16px;
      padding-bottom: 16px;
      font-size: 16px;
      font-weight: 400;
      color: #3e3e59;
  
      &:last-child {
        border-bottom: none;
      }
    }
  
    td {
      padding: 0px;
  
      p {
        font-weight: normal;
        color: #605F5F;
        font-size: 13px;
        width: 75%;
      }
    }
  
    td:first-child {
      font-size: 16px;
      font-weight: 600;
      width: 350px;
    }
  
    td:last-child {
      width: 650px;
    }
  
    select,
    input {
      min-width: 100%;
    //  color: #CBCBCB !important;
    }
  
  
    input[type="radio"],
    input[type="checkbox"] {
      min-width: inherit !important;
    }
  
    select:focus,
    input:focus {
      color: #343a40 !important;
    }
  
    .input-group input {
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
      border-right: none;
    }
  
    ::placeholder {
      color: #CBCBCB !important;
    }
  
    .input-group-text {
      height: 100%;
      border-left: none;
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      background-color: white;
    }
  
    .input-group-img {
      background-repeat: no-repeat;
      height: 100%;
      width: 12px;
      margin-top: 12px;
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23CBCBCB%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
    }
  
    .form-control:focus {
      box-shadow: none;
      border-color: #CBCBCB;
    }
  
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #CBCBCB !important;
    }
  
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #CBCBCB !important;
    }
  
    .form-select {
      padding: 8px 12px;
      background-position: right 0.75rem center !important;
      --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23CBCBCB' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
    }
  
    .form-select:focus {
      border: 1px solid #CBCBCB;
      box-shadow: none;
      color: black;
      --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")
    }
  }


  @media screen and (max-width: 576px) {
    .add-table td {
      display: block;
      min-width: 300px;
    }
    .add-table tr{
      border-bottom: none;
      padding: 8px 0;
    }
    .upload-item-container {
      flex-direction: column;
      width: 100%;
      // justify-content: space-around;
      .upload-item-content {
        width: 100%;
      }
  
      .upload-item {
        width: 100%;
      }
    }
  
    .add-table td:first-child {
  
      font-size: 16px;
      font-weight: 600;
      min-width: 200px;
    }
  
    .add-table td:last-child {
      min-width: 100%;
      width: 100%;
      max-width: 0;
      margin-top: 8px;
      margin-bottom: 16px;
    }
  }
input[type="search"] {
    display: inline;
    height: 20px;
    border: none;
    background: transparent;
    font-size: 16px;
    font-weight: 500;
    width: 80px;
    transition: all 300ms;
    padding: 4px;
    direction: rtl;
}

input[type="search"]:focus {
    outline: none;
    direction: ltr;
    border-bottom: 1px solid black;
    width: 120px;
}

input[type="search"]::placeholder {
    color: black;
}

input[type="search"]::-ms-clear {
    display: none !important;
}

.order-search {
    float: right;
    text-align: right;
    order: 2;
}
.order-filter {
    order: 3;
}
.add-product input[type="search"] {
    direction: rtl;
    width: calc(100% - 60px);
}

.add-product input[type="search"]:focus {
    width: calc(100% - 60px);
}

@media screen and (max-width: 576px) {
    .order-modal-container {
        width: 90%;
        height: 70%;
        overflow-y: scroll;
    }
    .suggest-price-modal-container{

        width: 80%;
        max-height: 70%;
        overflow: scroll;
    }
    .order-search {
        order: 3;
    }
    .order-filter {
        order: 2;
        text-align: left !important;
    }
    .add-product input[type=search]:focus{
        width: calc(100% - 30px);
    }
}

@media screen and (max-width: 576px) {
    // main {
    //     width: 100%;
    //     display: inline-block;
    // }

    // .item1 {
    //     padding: 0 16px;
    // }s

    // .item2 {
    //     display: none;
    // }

    // .item3 {
    //     grid-column-start: 1;
    //     grid-column-end: 2;
    //     padding: 12px;
    // }
}



.MuiMenu-root.MuiPopover-root {
    z-index: 9999 !important;
}

.form-check .form-check-input {
    float: left ;
}

.questions-tab .form-check .form-check-input {
    float: right !important;
}

.upload-modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 40%;

    h5 {
        font-weight: 900;
    }

    ul {
        padding: 0;
        margin-top: 16px;

        li {
            list-style: none;
        }
    }
}

#dropzone {
    margin-top: 20px;
    margin-bottom: 20px;

    & > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-width: 2px;
        border-radius: 2px;
        border-color: #eeeeee;
        border-style: dashed;
        background-color: #fafafa;
        color: #bdbdbd;
        outline: none;
        transition: border .24s ease-in-out;
    }
}