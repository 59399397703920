#file-uploader, #file-uploader1 {
    height: 0;
    width: 0;
    outline: none;
    border: none;
    visibility: none;
    position: absolute;
}

.file-uploader {
    border: 2px solid black !important;
}